<template>
  <v-container fluid>
    <v-row>
      <v-breadcrumbs :items="breadCrumbsItems" large>
        <template v-slot:divider>
          <v-icon size="11">mdi-greater-than</v-icon>
        </template>
      </v-breadcrumbs>
    </v-row>

    <v-row>
      <v-col cols="12" md="3">
        <DistibutorMenu></DistibutorMenu>
      </v-col>

      <v-col cols="12" md="9" class="mt-2">
        <v-row class="rowBg pa-5" v-if="userDetails.isActive">
          <v-col
            cols="12"
            md="3"
            offset-md="1"
            style="background-color:#ffffff;border-radius:10px;"
          >
            <v-row wrap justify-center>
              <v-col cols="12">
                <v-row>
                  <v-icon size="50">mdi-clipboard-text-clock-outline</v-icon>
                </v-row>

                <v-row>
                  <h3 class="mt-3" justify-center align="center">0</h3>
                </v-row>
              </v-col>
            </v-row>
            <v-btn
              block
              class="buttonStyle mt-5"
              :to="'/en/distributor/0/products'"
              >All Products</v-btn
            >
          </v-col>

          <v-col
            cols="12"
            md="3"
            offset-md="1"
            style="background-color:#ffffff;border-radius:10px;"
          >
            <v-row wrap justify-center>
              <v-col cols="12">
                <v-row>
                  <v-icon size="50">mdi-clipboard-text-clock-outline</v-icon>
                </v-row>

                <v-row>
                  <h3 class="mt-3" justify-center align="center">0</h3>
                </v-row>
              </v-col>
            </v-row>
            <v-btn block class="buttonStyle mt-5">Track Order</v-btn>
          </v-col>

          <v-col
            cols="12"
            md="3"
            offset-md="1"
            style="background-color:#ffffff;border-radius:10px;"
          >
            <v-row wrap justify-center>
              <v-col cols="12">
                <v-row>
                  <v-icon size="50">mdi-clipboard-text-clock-outline</v-icon>
                </v-row>

                <v-row>
                  <h3 class="mt-3" justify-center align="center">0</h3>
                </v-row>
              </v-col>
            </v-row>
            <v-btn block class="buttonStyle mt-5">Total Orders</v-btn>
          </v-col>
        </v-row>

        <!--Recent ManufacturersProduct Products-->
        <v-row class="mt-4 mb-5" v-if="userDetails.isActive">
          <v-col cols="12">
            <h5>Latest Product from Manufacturers</h5>
            <v-row
              wrap
              class="pa-10"
              style="background-color: #f2f2f2"
              v-if="manufacturesProducts.length >= 1"
            >
              <v-col
                cols="6"
                md="3"
                v-for="(product, index) in manufacturesProducts"
                :key="index"
              >
                <v-img
                  contain
                  class="productImg"
                  height="150"
                  width="100%"
                  :src="product.productImagePath"
                 @click="viewSingleProduct(product)"
                  style="background-color:#FFFFFF;"
                >
                   <h6 align="center" class="priceStyle2" v-if="product.needsPrescription == false">
                    Sale!
                  </h6>

                  <h6 align="center" class="priceStyle3" v-if="product.needsPrescription">
                    Prescription
                  </h6>
                </v-img>
               

                <p style="background-color:#ffffff; padding:10px;">
                  
                  <span style="font-size:11px;"
                    ><b>{{ product.name }}</b></span
                  >
                  <br />
                  <span
                    ><b
                      >{{ systemSetting.systemCurrency }} {{ product.price }}</b
                    >
                  </span>
                  <br/>
                  <v-btn class="buttonStyle mr-5" small  @click="viewSingleProduct(product)">
                            Buy Now
                          </v-btn>
                </p>
              </v-col>
              <v-dialog v-model="dialogSingleProduct" width="800">
                <v-card>
                  <v-card-text class="mt-4">
                    <v-row wrap>
                      <v-col cols="6">
                        <v-img
                          height="250"
                          :src="singleProduct.productImagePath"
                          contain
                        />
                      </v-col>
                      <v-col cols="6">
                        <div>
                          <h4>{{ singleProduct.name }}</h4>
                          <p>
                            <b>
                              {{ systemSetting.systemCurrency }}
                              {{ singleProduct.price }}
                            </b>
                          </p>
                          <p>
                            {{ singleProduct.description }}
                          </p>
                          <p><b>Availability:</b> In Stock</p>
                          <p>
                            <b>Quantity:</b>
                            <span class="ml-5">
                              <v-icon
                                dark
                                color="#72bd61"
                                class="mr-3"
                                @click="decreamentCounter"
                                >mdi-minus-circle</v-icon
                              >
                              {{ cartData.quantity }}
                              <v-icon
                                dark
                                color="#72bd61"
                                class="ml-3"
                                @click="increamentCounter"
                                >mdi-plus-circle</v-icon
                              >
                            </span>
                          </p>
                          <p class="mt-5" style="color:#F01010">
                            Quantity supplies are measured in Cartoons
                          </p>
                        </div>

                        <v-divider></v-divider>

                        <div>
                          <v-btn class="buttonStyle mr-5 ml-5" @click="addCart">
                            Add to Cart
                          </v-btn>

                          <v-btn class="buttonStyleGray" :to="'/cart/overview'">
                            Checkout
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogAddToCart" width="700" persistent>
                <v-card>
                  <v-card-text>
                    <v-row>
                      <v-col cols="11" align="center">
                        <v-icon size="60" color="#72BD61"
                          >mdi-check-circle</v-icon
                        >
                        <br />
                        <span style="font-size:16px;">
                          {{ singleProduct.name }} has been added to your
                          shopping cart.
                        </span>
                        <br/>
                          <v-btn class="buttonStyle mr-5 ml-5 mt-5" @click="reloadPage">
                            Continue
                          </v-btn>
                      </v-col>

                      <v-col cols="1" align="right">
                        <v-btn
                          icon
                          color="black"
                          @click="reloadPage"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>

                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-row>
           

            <v-row wrap class="pa-10" v-if="manufacturesProducts.length <= 0">
              <v-col cols="12" align="center" justify="center">
                <p>
                  <v-img
                    alt="System Logo"
                    class="shrink mr-2"
                    contain
                    :src="'img/emptyProductList.gif'"
                    transition="scale-transition"
                    width="350"
                  />
                </p>
                <p class="text-h5">
                  No Product Available
                </p>
              </v-col>
            </v-row>

            <!---- Edit Member Profile---->
          </v-col>
        </v-row>

        <v-row wrap v-else class="mb-10">
          <v-col cols="12" align="Center">
              <v-layout wrap>
                <v-col cols="12" align="Center">
                  <v-icon size="200" color="red">mdi-alert-circle</v-icon>
                </v-col>
              </v-layout>
              <h3>Account awaiting approval</h3>
              <v-layout wrap>
                <v-col cols="12" md="10" offset-md="1" align="Center">
                  <p>Hello <b>{{userDetails.name}}</b>! We are glad to have you sign up on qikPharma as a <b>Distributor</b>. Your account is currently under review and will be approved sooner than you can imagine.</p>
                </v-col>
              </v-layout>
             
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DistibutorMenu from "../others/DistributorMenu.vue";
import { Country } from "country-state-city";

export default {
  name: "DistributorDashboard",

  components: {
    DistibutorMenu,
  },
  data: () => ({
    dialogShipping: false,
    dialogAddToCart: false,
    countrySeleted: "",
    breadCrumbsItems: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Overview",
        disabled: true,
        href: "/overview/distributor_dashboard/en/home",
      },
    ],
    country: [],
    dialogSingleProduct: false,
    rating: 1,
    manufacturesProducts: [],
    systemSetting: {
      systemCurrency: "GH₵",
    },
    singleProduct: {},
    quantity: 0,
    cartData: {
      productId: "",
      quantity: 1,
    },
    roleBody: {
      name: "manufacturer",
    },
    userDetails:{
      isActive:true,
    },
  }),
  created() {
    const self = this;
    self.getUserDetails();
    self.getCountry();
    self.getManufacturesProducts();
  },
  methods: {
    getUserDetails() {
      this.$http
        .get(`${process.env.VUE_APP_URL}user/` + localStorage.getItem("userId"))
        .then((response) => {
          this.userDetails = response.data.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    increamentCounter() {
      this.cartData.quantity = this.cartData.quantity + 1;
    },
    decreamentCounter() {
      if (this.cartData.quantity <= 0) {
        this.cartData.quantity = 1;
      }
      this.cartData.quantity = this.cartData.quantity - 1;
    },
    viewSingleProduct(product) {
      this.dialogSingleProduct = true;
      this.singleProduct = product;
      this.cartData.productId = product.productId;
    },
    getCountry() {
      this.country = Country.getAllCountries();
    },
    getManufacturesProducts() {
      this.$http
        .post(`${process.env.VUE_APP_URL}product/role`, this.roleBody)
        .then((response) => {
          if (response.data.success == true) {
            console.log(response.data.data);
            this.manufacturesProducts = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    addCart() {
      this.$http
        .post(
          `${process.env.VUE_APP_URL}cart/add/` +
            localStorage.getItem("userId"),
          this.cartData
        )
        .then((response) => {
          this.dialogAddToCart = true;
          const cartListItems = response.data.data.CartItems;
          let lastCartItem = cartListItems[cartListItems.length - 1];
          let recentCart = JSON.parse(localStorage.getItem("cartItems"));
          recentCart.push(lastCartItem);
        })
        .catch((error) => {
          console.log(error.reponse);
        });
    },
    reloadPage(){
      location.reload();
    }
  },
};
</script>

<style scoped>
.priceStyle2 {
  width: 60px;
  font-size: 12px;
  height: auto;
  background-color: #bd2121;
  color: #ffffff;
  padding: 5px;
  border-bottom-right-radius: 5px;
}

.priceStyle3 {
  width: 100px;
  font-size: 12px;
  height: auto;
  background-color: #198754;
  color: #ffffff;
  padding: 5px;
  border-bottom-right-radius: 5px;
}

.rowBg {
  background-color: #f2f2f2;
  border-radius: 10px;
}

.iconPadding {
  padding: 50px !important;
}

.iconStyle {
  color: yellowgreen;
  font-size: 45px;
}
</style>
